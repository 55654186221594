img.ws_imgframe {
    padding: 0 -10px !important;
    margin: -16px 0 0 0 !important;
    height: 200px;
}

p.p-small {
    font-size: 10pt;
    font-style: italic;
}

DIV.img-cropper-250 {
    height: 230px;
    overflow:hidden;
    position: relative;
    margin: -10px 0 5px 0;
}

DIV.img-cropper-250 > IMG {
    margin: -110px 0 0 0;
}

A.dark {
    color: #000000;
    font-size: 10pt;
}