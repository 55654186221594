html {
  position: relative;
  min-height: 150%;
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

   
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  body {
  	color: #ffffff;
    margin-bottom: 60px; /* footer height */
  }

body::after {
  content: "";
  background: url(../assets/img/dark_bg_neon.jpg);
  background-attachment: fixed;
  background-repeat:repeat-y;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}

a {
  color: #ffffff;
  text-decoration: underline;
}
a:hover {
  text-decoration-color: #ff0000;
}
a.social-icon {
  text-decoration: none;
}

main > .container {
  margin: 60px 0 0 0;
  padding: 160px 15px 0;
  color: #ffffff;
}

footer {
  padding: 0px 15px;
  font-size: 12pt;

    position: absolute;
  	bottom: 0;
  	left: 0;
  	right: 0;

}

#nav-pills {
  position: fixed;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.white, .white a {
  color: #fff !important;
}

.gray, .gray a {
	color: #ccc !important;
}

.disableSpan {
  pointer-events:none;
}

.fa { display:inline; }

.fa-fw{
  padding-right: 20px !important;
}

/* breadcrumb */
DIV.breadcrumb {
  font-size: 9pt;
  margin: 0px;
}
.breadcrumb span {
  font-weight: bolder;
  margin: 0 4px;
}
.breadcrumb span.caret {
  color: #555555;
}
.breadcrumb span.current {
  color: #999999;
}
.breadcrumb span.link a {
  text-decoration: underline;
  color: #999999;
}
.breadcrumb span.link a:hover {
  text-decoration-color: #ff0000;
  color: #999999;
}

/* about page feature */
.feature-icon {
  width: 4rem;
  height: 4rem;
  border-radius: .75rem;
}

.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: .75rem;
}

.text-shadow-1 { text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25); }
.text-shadow-2 { text-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25); }
.text-shadow-3 { text-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25); }

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.feature-icon-small {
  width: 3rem;
  height: 3rem;
}

/* nav link buttons */
.btn-sgai-nav {
  color: #fff;
  font-size: 11pt;
  text-align: left;
  margin: 1px 0;
}
.btn-sgai-nav:focus, .btn-sgai-nav:active, .btn-sgai-nav.active, .open>.dropdown-toggle.btn-sgai-nav {
  color: #fff;
  background-color: #333344;
  border-color: #444444; /*set the color you want here*/
}
.btn-sgai-nav:hover {
  color: #fff;
  background-color: #333344;
  border-color: #666666; /*set the color you want here*/
}

/* CAROUSEL */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

.carousel .carousel-item {
  height: 300px;
  background: transparent !important;
}

.carousel-item img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    min-height: 500px;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: left;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
  margin-top: 1rem !important;
}

.featurette-heading span.text-muted {
  color: #888888 !important;
}

P.lead {
  font-size: 1rem;
}

/* rtl:end:remove */

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 36px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 2rem;
  }
}

.marker {
  background-image: url('../assets/img/mapbox-icon.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.btn-xs.btn-outline-light {
  border-color: #aaaaaa !important;
  color: #aaaaaa !important;
}

.btn-xs.btn-outline-light:hover {
  border-color: #bbbbbb !important;
  color: #444444 !important;
}

.btn-hpad-2 {
  padding: 0 2px;
}

.btn-hmar-2 {
  margin: 0 2px;
}


DIV.team_photo {
  text-align: center;

}
DIV.team_photo IMG {
  max-width: 200px !important;
  border-radius: 100px;
}
DIV.team_desc {
  text-align: center;
}
DIV.team_desc a {
  color: #cccccc;
}

/* job related styles */
DIV.job_details {
  margin: 6px 0 30px 20px;
}

a.job_link {
  color: #ffffff;
  font-size: 14pt;
  text-decoration: dashed;
}

a.job_link:hover {
  text-decoration: underline;
  text-decoration-color: #ff0000;
}

a.job_link[data-bs-toggle="collapse"]:before {
  margin-right: 4px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0d8";
  transform: rotate(90deg) ;
  transition: all linear 0.25s;
}   
a.job_link[data-bs-toggle="collapse"].collapsed:before {
  transform: rotate(90deg);
  content: "\f0d8";
}

IMG.max-w-100 {
  max-width:100%;
}

IMG.max-h-200 {
  max-height:200px;
  padding: 0 20px;
}

IMG.max-px-300 {
  width:300px;
  padding: 0 20px;
}


IMG.roundy {
  border-radius: 4px;
}

IMG.roundy20 {
  border-radius: 20px;
}

IMG.pull-right {
  float:right;
  clear:both;
}

IMG.pull-left {
  float:left;
  clear:both;
}

IFRAME.pull-right {
  float:right;
  padding: 0 8px;
}

SPAN.red {
  color: red;
}

.font-w-400 {
  font-weight: 400;
}

LI.no-bullet {
  list-style-type: none;
}

UL.pad-v5 > LI {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* press feature */
DIV.press_feature {
  background-color: #222222;
  border:#555555 1px solid;
  border-radius: 10px;
  padding: 4px;
  margin: 5px;
}

DIV.press_feature > DIV.press_media_type {
  position:absolute;
}

DIV.press_img_wrapper {
  width: 48%;
  float:left;
  overflow: hidden;
}

DIV.press_img_wrapper > IMG.press_img {
  max-height: 140px;
}

DIV.press_feature > DIV.press_text {
  width: 48%;
  float:right;
  margin: 5px;
}

DIV.press_feature > DIV.press_text > DIV.press_date {
  font-weight: bold;
  font-size: 8pt;
  color: #cccccc;
}

DIV.press_feature > DIV.press_text > DIV.press_title {
  font-weight: normal;
  font-size: 11pt;
  color: #ffffff;
}

DIV.press_feature > DIV.press_text > DIV.press_descr {
  font-weight: normal;
  font-size: 11pt;
  color: #cccccc;
}