DIV.quote {
    border: #777777 1px solid; 
    border-radius: 10px; 
    background: rgba(71, 70, 81, .3)
}

DIV.quote > DIV.quotetext {
    font-size:16pt; 
    font-weight: bold; 
    text-align: left;
}

DIV.quote > DIV.quotecredit {
    text-align: right; 
    font-size:9pt; 
    padding-top:8px;
}

IMG.feature-image {
    max-width: 100%; 
    border-radius: 12px;
}