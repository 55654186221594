  canvas.game404 {
    position : relative;
    width: fit-content;
    height: 400px;
    margin-left: 40px;
    background-color: #000000;
    border-radius: 8px;
    padding: 14px;
  }

  DIV#page {
    font-family: "Lato", "Lucida Grande", "Lucida Sans Unicode", Tahoma, Sans-Serif;
    font-size : 18px;
  }
  